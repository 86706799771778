<template>
  <div>
    <b-overlay :show="processing" bg-color="#ffffff" z-index="501" opacity="0.6">
      <h6 class="mb-1 mt-1 text-center">登入</h6>
      <validation-error-alert :errors="validationErrors" v-if="validationErrors"></validation-error-alert>
      <b-form @submit.prevent="" class="av-tooltip tooltip-label-bottom">
        <!-- <b-form-group label="Email" class="has-float-label mb-4">
          <b-form-input type="email" v-model="email"/>
        </b-form-group> -->
        <div class="form-group">
          <label class="float-label">Email</label>
          <input type="email" class="form-control" v-model="email" placeholder="電子郵件"/>
        </div>
        <div class="form-group">
          <label class="float-label">密碼</label>
          <input type="password" class="form-control" v-model="password"/>
        </div>
        <b-button class="btn-block primary-button login-button" size="lg" :disabled="processing" @click="submit">
          登入
        </b-button>
        <div class="mt-4">
          <router-link class="float-left text-bold register-highlight" :to="{path: '/' + $route.params.org_code + '/liff/register'}">註冊成為會員</router-link>
          <router-link class="float-right" :to="{path: '/' + $route.params.org_code + '/liff/forgotpassword'}">忘記密碼了嗎？</router-link>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import axios from 'axios'

export default {
  data() {
    return {
      processing: false,
      loginError: false,
      email: null,
      password: null,
      validationErrors: null,
    }
  },
  computed: {
    ...mapState('liffCustomerBind', {
      organization: state => state.organization,
      lineData: state => state.lineData,
    })
  },
  methods: {
    async submit() {
      let _this = this;
      _this.processing = true

      const url = process.env.VUE_APP_API_BASE_URL + `/${_this.organization.code}/liff/login`
      let params = new URL(location.href).searchParams

      await axios.post(url, {
        line_id: _this.lineData.profile.userId,
        line_access_token: _this.lineData.accessToken,
        email: _this.email,
        password: _this.password,
        redirect_url: params.get('redirect_url') ?? null,
      }).then(response => {
        if (response.data.status === 'success' && response.data.data.url) {
          window.location.href = response.data.data.url;
        } else {
          this.$swal.fire({
            text: '登入失敗，請檢查帳號密碼',
            type: 'error'
          })
          _this.processing = false;
        }
      }).catch(error => {
        if (error.response.status === 422) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            text: '登入失敗',
            type: 'error'
          })
        }
        _this.processing = false;
      });
    }
  }
}
</script>

<style>
.login-button {
  margin-top: 36px;
}

.register-highlight {
  color: #007bff;
}
</style>
